import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { Message } from "primereact/message";
import { ToastContainer, toast } from "react-toastify";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import Lottie from "lottie-react";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import "./style.css";
import animationData from "../../content/lotties/404.json";
import GlobalContext from "../../helpers/GlobalContext";

import { Carrinho } from "../../models/Carrinho";
import { Evento } from "passemix-lib/src/models/Evento";
import { Lote } from "passemix-lib/src/models/Lote";
import { ItemVenda } from "passemix-lib/src/models/ItemVenda";

import { EventoService } from "../../services/EventoService";
import { LoteService } from "../../services/LoteService";
import { UtilsHelper } from "passemix-lib/src/helpers/UtilsHelper";
import { GlobalService } from "../../services/GlobalService";
import { ComissarioService } from "../../services/ComissarioService";
import { VendaService } from "../../services/VendaService";
import { Comissario } from "passemix-lib/src/models/Comissario";

export interface IEventoComponent {
  nomeEvento: string;
  chaveComissario: any;
}

const EventoComponent: React.FC<IEventoComponent> = (props) => {
  const history = useHistory();
  const { setCarrinho } = useContext(GlobalContext);
  const [eventoExistente, setEventoExistente] = useState<boolean>(true);
  const [vendaDisponivel, setVendaDisponivel] = useState(true);
  const [desativaBuscaLote, setDesativaBuscaLote] = useState(false);
  const [evento, setEvento] = useState<Evento>(new Evento());
  const [lotes, setLotes] = useState<Lote[]>(new Array<Lote>());
  const [itensCarrinho, setItensCarrinho] = useState<ItemVenda[]>(
    new Array<ItemVenda>()
  );
  const [comissario, setComissario] = useState<Comissario>();

  const carregaEvento = useCallback(async () => {
    try {
      let service = new EventoService();
      let response = await service.obterPorUrl(props.nomeEvento);

      if (response !== undefined) {
        setEvento(response);

        document.title = response.nome;

        if (response.idPixel !== undefined) {
          ReactPixel.init(response.idPixel);
          ReactPixel.pageView();
        }

        if (response.tagGoogle !== undefined) {
          const tagManagerArgs = {
            gtmId: response.tagGoogle,
          };

          TagManager.initialize(tagManagerArgs);
        }
      } else {
        setEventoExistente(false);
        setTimeout(() => {
          history.push("/eventos");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  }, [history, props.nomeEvento]);

  useEffect(() => {
    carregaEvento();
  }, [carregaEvento]);

  const carregaLotesComissario = useCallback(async (): Promise<
    Lote[] | undefined
  > => {
    try {
      if (props.chaveComissario !== undefined) {
        let comissarioService = new ComissarioService();
        let response = await comissarioService.validarComissario(
          props.chaveComissario,
          evento.produtor.id
        );

        if (response !== undefined) {
          setComissario(response);
          let loteService = new LoteService();
          let lotesResponse = await loteService.obterPorEventoComissario(
            evento.id,
            props.chaveComissario
          );

          if (lotesResponse !== undefined) {
            for (let index = 0; index < lotesResponse.length; index++) {
              const element = lotesResponse[index];
              element.isComissario = true;
            }
            return lotesResponse;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evento.id, props.chaveComissario]);

  const carregaLotes = useCallback(async () => {
    try {
      let lotesEvento;

      if (evento.id && evento.loteDisponivel) {
        let loteService = new LoteService();
        let response = await loteService.obterPorEvento(evento.id);

        if (response !== undefined) lotesEvento = response;

        let lotesComissario = await carregaLotesComissario();

        let lotesResult;

        if (lotesEvento !== undefined && lotesComissario !== undefined) {
          lotesResult = [...lotesEvento, ...lotesComissario];
        } else if (lotesEvento !== undefined && lotesComissario === undefined) {
          lotesResult = [...lotesEvento];
        } else if (lotesEvento === undefined && lotesComissario !== undefined) {
          lotesResult = [...lotesComissario];
        }

        if (lotesResult !== undefined) {
          setLotes(lotesResult);
        } else {
          setVendaDisponivel(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [carregaLotesComissario, evento.id, evento.loteDisponivel]);

  useEffect(() => {
    carregaLotes();
  }, [carregaLotes]);

  const obterLotes = async () => {
    setDesativaBuscaLote(true);
    evento.loteDisponivel = true;
    await carregaLotes();
    setDesativaBuscaLote(false);
  };

  const adicionaIngresso = (lote: Lote) => {
    let limiteIngresso =
      evento.limiteIngresso !== 0 ? evento.limiteIngresso : 5;

    if (itensCarrinho.length < limiteIngresso) {
      let itensExistente: ItemVenda[] | undefined;

      itensExistente = itensCarrinho.filter((x) => x.lote.id === lote.id);

      if (lote.quantidade >= itensExistente.length + 1) {
        let itemVenda = new ItemVenda();
        itemVenda.lote = lote;
        setItensCarrinho([...itensCarrinho, itemVenda]);
      } else {
        toast.info(`Resta apenas ${lote.quantidade} ingressos`);
      }
    } else {
      toast.warn(`Limite de ${limiteIngresso} ingressos atingido`);
    }
  };

  const removeIngresso = (lote: Lote) => {
    if (itensCarrinho.length > 0) {
      let index = itensCarrinho.findIndex((x) => x.lote.id === lote.id);
      if (index > -1) {
        itensCarrinho.splice(index, 1);
        setItensCarrinho([...itensCarrinho]);
      }
    }
  };

  const comprar = async () => {
    if (itensCarrinho.length === 0) {
      toast.info("Nenhum ingresso foi selecionado");
    } else {
      let venda = await verificarDisponibilidade();

      const lotesAgrupados = venda.itensVenda.reduce((acc, item) => {
        const loteId = item.lote.id;
        if (!acc[loteId]) {
          acc[loteId] = [];
        }
        acc[loteId].push(item);
        return acc;
      }, {} as Record<number, ItemVenda[]>);

      const resultado = Object.entries(lotesAgrupados).map(
        ([loteId, itens]) => ({
          loteId: Number(loteId),
          disponivel: itens.some((item) => item.lote.disponibilidade),
        })
      );

      for (let index = 0; index < resultado.length; index++) {
        const element = resultado[index];
        if (element.disponivel === false) {
          toast.error(
            `A quantidade solicitada para o setor ${
              lotes.filter((x) => x.id === element.loteId)[0].setor
            } não está mais disponível`
          );
        }
      }

      if (!resultado.some((x) => x.disponivel === false)) checkout();
    }
  };

  const verificarDisponibilidade = async () => {
    let venda = {
      evento: evento,
      itensVenda: itensCarrinho,
      chaveComissario: props.chaveComissario,
    };

    var service = new VendaService();
    var response = await service.disponibilidade(venda);

    return response;
  };

  const checkout = () => {
    var carrinho = new Carrinho();
    carrinho.evento = evento;
    carrinho.itens = itensCarrinho;
    carrinho.chaveComissario = props.chaveComissario;

    setCarrinho(carrinho);

    let logado = GlobalService.isAutenticado();
    !logado ? history.push("/login", "checkout") : history.push("/checkout");
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={8000} />
      {evento?.id ? (
        <>
          <div className="grid">
            <div className="p-col-12 p-md-12 p-lg-8 p-xl-6 p-lg-offset-2 p-xl-offset-3">
              {evento.imagem ? (
                <div className="p-grid">
                  <div className="p-col-12">
                    <img
                      className="banner-evento"
                      src={`https://passemix-assets.s3.amazonaws.com/eventos/${evento.imagem}`}
                      alt={evento.nome}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="p-grid p-mb-3 p-pr-1 p-pl-1">
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                  <span className="text-event-title">{evento.nome}</span>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12 p-pb-0 p-pt-0 text-event-info">
                  <FontAwesomeIcon icon={faCalendarCheck} className="p-mr-1" />{" "}
                  {UtilsHelper.formatDateTime(
                    evento.data,
                    `dd/MM/yyyy 'às' HH:mm`
                  )}
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12 p-pt-0 text-event-info">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="p-mr-1" />{" "}
                  {evento.local} - {evento.cidade}
                </div>
              </div>

              <div className="p-grid p-mb-2">
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                  {!evento.loteDisponivel ? (
                    <div className="p-text-center p-pt-4">
                      <Button
                        label="Comprar Ingressos"
                        disabled={desativaBuscaLote}
                        className="p-button-rounded button-comprar-lotes"
                        onClick={() => obterLotes()}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {lotes.length > 0 && evento.loteDisponivel ? (
                    <div className="container-ingressos">
                      {lotes.map((lote, i) => (
                        <div className="p-grid p-m-1 lote-section" key={i}>
                          <div className="p-col-8 p-md-8 p-lg-8 p-xl-8">
                            <div className="p-mb-2 text-setor">
                              {lote.setor}
                            </div>
                            <div className="text-lote p-mb-3">
                              {lote.nome} - {lote.tipoIngresso}
                            </div>
                            <div className="text-preco-lote">
                              R$ {UtilsHelper.formatCurrency(lote.preco)}
                            </div>
                            {comissario && lote.isComissario ? (
                              <div className="container-nome-comissario">
                                Vendido por:{" "}
                                <span className="p-text-bold">
                                  {comissario.nome}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 p-text-left">
                            {lote.quantidade > 0 ? (
                              <div className="p-d-flex p-jc-end">
                                <div className="p-pt-3">
                                  <Button
                                    icon="pi pi-minus"
                                    className="p-button-rounded p-button-text p-button-outlined button-lote"
                                    onClick={() => removeIngresso(lote)}
                                  />
                                </div>
                                <div className="qtd-ingressos-selecionado">
                                  <span className="text-qtd-lote">
                                    {
                                      itensCarrinho.filter(
                                        (x) => x.lote.id === lote.id
                                      ).length
                                    }
                                  </span>
                                </div>
                                <div className="p-pt-3">
                                  <Button
                                    icon="pi pi-plus"
                                    className="p-button-rounded p-button-text p-button-outlined button-lote"
                                    onClick={() => adicionaIngresso(lote)}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="p-text-right text-lote p-pr-2">
                                <span>Indisponível</span>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="p-grid p-m-1 p-px-2 p-py-3 text-md">
                        {itensCarrinho.length > 0 ? (
                          <div className="p-col-12 p-md-5 p-lg-12 p-xl-12">
                            <div className="text-total p-mb-1">Total</div>
                            <div className="text-valor-total">
                              R${" "}
                              {UtilsHelper.formatCurrency(
                                itensCarrinho.reduce(
                                  (a, b) => a + b.lote.preco,
                                  0
                                )
                              )}{" "}
                              <span className="text-info">(+ taxas)</span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="p-col">
                          <Button
                            label="COMPRAR"
                            className="button-comprar"
                            onClick={() => comprar()}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {!vendaDisponivel ? (
                    <div className="p-text-center p-pt-4">
                      <Message
                        severity="info"
                        text="Vendas on-line indisponível"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {evento.descricao !== undefined ? (
                <div className="p-grid p-mt-5 p-mb-3">
                  <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="container-descricao">
                      <div
                        dangerouslySetInnerHTML={{ __html: evento.descricao }}
                      ></div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : eventoExistente ? (
        <div className="grid p-mt-3">
          <div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
            <Skeleton width="100%" height="5rem" className="p-mb-2" />
            <Skeleton width="100%" className="p-mb-2" />
            <Skeleton width="100%" className="p-mb-2" />
          </div>
        </div>
      ) : (
        <div className="p-text-center text-blue p-p-5">
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            rendererSettings={{
              preserveAspectRatio: "xMidYMid",
            }}
            style={{ height: "45vh" }}
          />
          <h3>Ops! Não foi possível localizar o evento.</h3>
        </div>
      )}
      <a
        href="https://api.whatsapp.com/send/?phone=5535998768359"
        target="_blank"
        rel="noreferrer"
        className="botao-whatsapp"
      >
        <FontAwesomeIcon icon={faWhatsapp} style={{ marginTop: "14px" }} />
      </a>
    </>
  );
};

export default EventoComponent;
